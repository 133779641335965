// Dt Prototype
import React, { Component } from 'react';
import withSizes from 'react-sizes';
import { Link } from 'gatsby';
import * as THREE from 'three';
import { GlitchEffect, EffectComposer, EffectPass, RenderPass, GlitchMode, BlurPass, VignetteEffect, BrightnessContrastEffect, BlendFunction, BloomEffect, KernelSize, DepthPickingPass, ShockWaveEffect, } from 'postprocessing';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import _ from 'lodash';
import { series } from 'async-es';
import e from '../helpers/easings';
import game from '../helpers/ee/Config';
import scenarios, { INTRO_DEFAULTS, DEFAULTS } from '../helpers/ee/Scenarios';
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { Easing, Tween, autoPlay, FrameThrottle } from 'es6-tween';
import { Interaction } from '../helpers/thirdparty/Interaction.js';  
import roomObjs from '../helpers/models/roomFull';
import * as Tone from 'tone';

const PATH_FURNITURES = '../room/';

const VERSION = '1.2.5';

const TASKS_COUNT = 5;
const DELAY_SHOW_COMPLETED = 1000;

const FPS = 60;
const FPS_MS = 1000 / FPS;
const CAM_FOV = 50;
const CAM_MIN_CLIP = 0.1;
const CAM_MAX_CLIP = 50000 * 10;

const CAM_SPEED_F = 0.2;
const CAM_SPEED_B = 0.2;

const DEFAULT_PLAYER_HEIGHT = INTRO_DEFAULTS.y;

const CAM_PLAYER_1 = 1;
const CAM_PLAYER_1_DEFAULT_Z = INTRO_DEFAULTS.z;
const CAM_PLAYER_1_DEFAULT_RX = INTRO_DEFAULTS.rX;
const CAM_PLAYER_1_DEFAULT_RY = INTRO_DEFAULTS.rY;
const CAM_PLAYER_1_DEFAULT_RZ = INTRO_DEFAULTS.rZ;
const CAM_PLAYER_1_DEFAULT_POSITION = new THREE.Vector3(INTRO_DEFAULTS.x, DEFAULT_PLAYER_HEIGHT, INTRO_DEFAULTS.z);

const POS_LOOK_AT = [
  new THREE.Vector3(0, 0, -100)
];

const DEBUG_CLOSE_X = -350;
const DEBUG_OPEN_X = 20;
const OPEN_X_EDGE = 0;
const OPEN_X = 60;
const CLOSE_X = -350;

const ORBIT_CAM_MIN_DISTANCE = CAM_PLAYER_1_DEFAULT_Z - 2000;
const ORBIT_CAM_MAX_DISTANCE = CAM_PLAYER_1_DEFAULT_Z + 2000;

const IDLE_LIMIT = 20000;

const VO_BED      = 'getting-up';
const VO_DISASTER = 'disaster';
const VO_CARES    = 'no-one-cares';
const VO_YAWN     = 'yawn-forget-it';

// Walls
// Ka 1.000000 1.000000 1.000000
// Kd 0.066556 0.060832 0.058559

// Interactive
const PRELOADED_OBJECTS = roomObjs;

const POI = {
  A: [
    { x:10, z:-8, rY:2.82 },
    { x:2, z:-9, rY:3.03 }
  ],
  B: [
    { x:2, z:-9, rY:3.10 },
    { x:5, z:1, rY:3.42 }
  ],
  C: [
    { x:3, z:-6, rY:3.03 },
    { x:-8, z:7, rY:4.3 }
  ],
}

const ndc = new THREE.Vector3();

class Dt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorSystem: null,
      errorRender: [],
      isSceneInitialized: false,
      elapsed: 0,
      dilatedElapsed: 0,
      gameState: game.getGameState(),
      activeCamera: CAM_PLAYER_1,
      hasVignette: false,
      isShowExit: false,
      isShowCompleted: false,
      isShowBranding: true,
      isShowDebug: false,
      tasks: [],
      percentLoaded: 0,
      loadingFile: '-',
      isLoaded: false,
      lastFinishedTask: null,
      isCompleted: false,
      completedTaskCount: 0,
      isShowInstructions: false
    };
    this.loaded = []; // All loaded instances of objs
    this.isInitializing = false;
    this.timer = null;

    autoPlay(true);
    FrameThrottle(FPS); // For Tweening
    
    this.isSSR = (typeof window === "undefined");
    
    this.camSpeed = 0;
    this.camRotX = CAM_PLAYER_1_DEFAULT_RX;
    this.camRotY = CAM_PLAYER_1_DEFAULT_RY;
    this.camRotZ = CAM_PLAYER_1_DEFAULT_RZ;
    this.camPosX = CAM_PLAYER_1_DEFAULT_POSITION.x;
    this.camPosY = CAM_PLAYER_1_DEFAULT_POSITION.y;
    this.camPosZ = CAM_PLAYER_1_DEFAULT_POSITION.z;
    this.hasFxGlitch = false;
    this.intensityFxVignette = 0;
    this.vignetteV = 0;
    this.cams = [];
    this.calloutTop = 150;
    this.isAudioPlaying = false;
    this.newRatio = 0;
    this.lastRatio = 1;
    this.isDebugOpen = true;
    this.isChecklistOpen = true;
    this.coreAmbientLightValue = 0.0;
    this.isSoundOn = false;
    this.prevScreenWiddth = null;
    this.keepInfoBoxOpen = false;
    
    this.timerIdle = null;
    
    this.tweenMove = new Tween();
    
    this.scannedObjects = [];
    
    // A fake compilable SSR
    this.ambiancePlayer = { start: ()=>{}, stop: (k)=>{} };
    this.audioPlayer = { start: ()=>{}, stop: (k)=>{} };
    if (!this.isSSR) {
      this.audioPlayer = new Tone.Player("/vo/call-a-friend.wav").toDestination();
      this.ambiancePlayer = new Tone.Player("/sfx/ambiance.mp3").toDestination();
    }
    this.audioPlayer.fadeOut = .5;
    this.audioPlayer.onstop = () => {
      const { isCompleted } = this.state;
      // Show the Completed Popup once the VO is finished and Tasks are completed
      if (isCompleted) {
        _.delay(() => {
          this.setState({isShowExit: false});
          this._showOnceCompletedPopup();
        }, DELAY_SHOW_COMPLETED);
      }
    };

    this._playOnceTask0 = _.once(() => {
      this._loadAndPlayVO(VO_BED);    
    });

    this._showOnceCompletedPopup = _.once(() => {
      this._showCompletedPopup();
    });
  }

  componentDidMount() {
    this._bootstrap();
  }

  componentWillUnmount() {
    game.quit();
    this.stop();
    this.renderer.domElement.removeEventListener("mousemove", this);
    document.removeEventListener("keyup", this);
    this.mount.removeChild(this.renderer.domElement);
    this.ambiancePlayer.stop();
    this.audioPlayer.stop();
  }
  
  handleEvent(event) {
    switch(event.type) {
      case "mousemove":
        void this.pickDepth(event);
        break;
      case "keyup":
        void this.keySupport(event);
        break;
    }
  }

  start = () => {
    game.start();
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    _.delay(() => {
      requestAnimationFrame(this.animate);
    }, FPS_MS);
    this.renderScene();
  };

  initializeScene = () => {
    if (!this.isInitializing) {
      this.isInitializing = true;
      this._setupEngine().then(() => {
        this._makeBlockingObjects().then(() => {
          this._preload().then(() => {
            this.setState({
              isSceneInitialized: true,
            }, () => this._loadComplete());
          });
        });
      }).catch(err => console.log(err));
    }
  }

  _loadComplete = () => {
    // this.controls = new OrbitControls(this.cams[CAM_PLAYER_1].camera, this.renderer.domElement);
    // this.controls.minDistance = ORBIT_CAM_MIN_DISTANCE;
    // this.controls.maxDistance = ORBIT_CAM_MAX_DISTANCE;
    
    // Setting raycast interactables.
    const { activeCamera } = this.state;
    if (!this.isSSR && this.cams[activeCamera]) {
      new Interaction(this.renderer, this.scene, this.cams[activeCamera].camera, { scannedObjects: this.scannedObjects });  
    }
    this._renderFrame();
  }
  
  _makeBlockingObjects() {
    return new Promise(resolve => {
      const geometry = new THREE.BoxGeometry( 6.5, 5, 2 );
      const material = new THREE.MeshPhongMaterial({
        color:     0x000000, 
        specular:  0,
        shininess: 0,
        flatShading: true,
        opacity: 0,
        transparent: true,
      });
      const cube = new THREE.Mesh( geometry, material );
      cube.position.set( 11.4, 0, -9.2 );
      this.scene.add( cube );
      this.scannedObjects.push(cube);
      return resolve(true);
    });
  }

  _preload() {
    return new Promise((resolve, reject) => {
      this.loaded = [];
      const manager = new THREE.LoadingManager();
      manager.addHandler( /\.dds$/i, new DDSLoader() );
      
      const cbs = [];
      let i = 0;
      _.each(PRELOADED_OBJECTS, (o) => {
        cbs.push(
          (callback) => {
            new MTLLoader(manager)
              .setPath(PATH_FURNITURES)
              .load(o.obj+'.mtl', (mat) => {
                mat.preload();
                new OBJLoader(manager)
                  .setMaterials(mat)
                  .setPath(PATH_FURNITURES)
                  .load(o.obj+'.obj', (object) => {
                    // console.log('loaded', i, o.obj);
                    object.lumen = {};
                    object.lumen.name = o.obj;
                    object.lumen.task = o.task;
                    object.lumen.vo = o.vo;
                    object.position.x = o.pos[0];
                    object.position.y = o.pos[1];
                    object.position.z = o.pos[2];
                    object.castShadow = true;
                    
                    if (o.interactive === true) {
                      // Temporarily target an object
                      object.cursor = 'pointer';
                      object.on('click', ev => this._objClick(ev));  
                      object.on('mouseover', ev => {
                        // console.log('mouseover', ev.target);
                        var outlineMaterial1 = new THREE.MeshBasicMaterial( { color: 0xffffff, side: THREE.BackSide } );
                        var outlineMesh1 = new THREE.Mesh( ev.data.target, outlineMaterial1 );
                        outlineMesh1.position.set(ev.data.target.position)
                        outlineMesh1.scale.multiplyScalar(1.05);
                        // this.scene.add( outlineMesh1 );
                      });
                      object.on('mouseout', ev => {
                        // console.log('mouseout', ev.target);
                        var outlineMaterial1 = new THREE.MeshBasicMaterial( { color: 0xffffff, side: THREE.BackSide } );
                        var outlineMesh1 = new THREE.Mesh( ev.data.target, outlineMaterial1 );
                        outlineMesh1.position.set(ev.data.target.position)
                        outlineMesh1.scale.multiplyScalar(1.05);
                        // this.scene.add( outlineMesh1 );
                      });
                      
                      this.scannedObjects.push(object);
                    }

                    this.scene.add(object);
                    // this.tweenLoader(i, PRELOADED_OBJECTS.length);
                    this.tweenCounter(i, PRELOADED_OBJECTS.length);
                    callback(null, ++i);
                  },
                  (p) => { 
                    // console.log(o.obj, 'load progress', (p.loaded/p.total)*100, 100, p);
                    // this.tweenLoader((p.loaded/p.total)*100, 100);
                    // this.tweenCounter((p.loaded/p.total)*100, 100);
                    this.setState({
                      percentLoaded: Math.round((p.loaded/p.total)*100),
                      loadingFile: o.name,
                    });
                  },
                  (e) => { callback(e); }
                );
              },
              (p) => { /* console.log('mat progress', p); */ },
              (e) => { callback(e); }
            );
          }
        )
      });
      series(cbs, (err, res) => {
        if (err) {
          return reject(err);
        }
        
        this.tweenCounter(i, PRELOADED_OBJECTS.length);
        // console.log("Models loaded:", i);
        this.setState({
          isLoaded: true,
          percentLoaded: 100,
          loadingFile: "Scene",
        });
        
        // Resolve broken white lines
        this.scene.traverse(node => {
          if ( node.isLine ) {
            // console.log('broken node', node);
            node.isLineSegments = true;
            node.visible = false;
          }
        });
        
        this.toggleVignette();
        
        // this.tweenLoader(100, 100);
        
        return resolve();
      });
    });
  }
  
  _loadAndPlayVO(src) {
    this.audioPlayer.load(`/vo/${src}.wav`)
    .then(() => {
      this.audioPlayer.start();
    });
  }

  _showCompletedPopup() {
    this.setState({
      isShowCompleted: true,
      isCompleted: true
    }, () => {
      this.audioPlayer.stop();
      this.ambiancePlayer.stop();
      this.mountCompleted.style.right = OPEN_X_EDGE+'px';
      this.mountRestart.style.right = OPEN_X_EDGE+'px';
      this.mountExit.style.right = CLOSE_X+'px';
    });
  }
  
  async keySupport(event) {
    if (event.key === "v") {
      this.isDebugOpen = true;
      this.setState({
        isShowDebug: !this.state.isShowDebug
      }, () => {
        if (this.state.isShowDebug) {
          if (this.mountDebugMini) {
            this.mountDebugMini.style.right = DEBUG_OPEN_X+'px';  
          }
        }
      });
    }
  }
  
  async pickDepth(event) {
    ndc.x = (event.clientX / window.innerWidth) * 2.0 - 1.0;
    ndc.y = -(event.clientY / window.innerHeight) * 2.0 + 1.0;
  
    ndc.z = await this.cams[CAM_PLAYER_1].depthPickingPass.readDepth(ndc);
    ndc.z = ndc.z * 2.0 - 1.0;
  
    // Convert from NDC to world position.
    this.cursor.position.copy(ndc.unproject(this.cams[CAM_PLAYER_1].camera));
  }
  
  _fulfillTask(idx) {
    return new Promise((resolve) => {
      if (idx === 0) {
        this._playOnceTask0();
      }
      let { tasks } = this.state;
      tasks[idx] = true;
      this.setState({ tasks }, () => {
        this._validateTasks();
        return resolve(idx);
      });
    });
  }
  
  _objClick(ev) {
    console.log('_objClick', ev.target.lumen);
    // Callout no more necessary
    // if (ev.target.lumen.name == TEMP_OBJ_TARGET) {
    //   this.startTweenBlur();
    //   this.showCallout();
    // }
    // if (!this.isAudioPlaying) {
    //   this.audioPlayer.start();
    //   this.isAudioPlaying = true;
    // }

    if (this.cursor && this.cursor.position) {
      console.log('boom', this.cursor.position);
      this.cams[CAM_PLAYER_1].fxShockWave.epicenter.copy(this.cursor.position);
      this.cams[CAM_PLAYER_1].fxShockWave.explode();  
    }
    
    if (ev.target.lumen.task) {
      this._fulfillTask(ev.target.lumen.task);
    }

    this._loadAndPlayVO(ev.target.lumen.vo);
  }

  _bootstrap() {
    // add scene
    this.scene = new THREE.Scene();
    this.scene.name = 'scene-intro';
    // this.scene.fog = new THREE.Fog(0x4A2D2E, 0, 10000);
    const axesHelper = new THREE.AxesHelper(100);
    // this.scene.add(axesHelper);

    this.ambientLight = new THREE.AmbientLight(0xffffff, this.coreAmbientLightValue);
    this.scene.add(this.ambientLight);
    
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
    this.directionalLight.target = this.scene;
    // this.directionalLight.position.set( 0, 0, 100 );
    // this.directionalLight.lookAt(new THREE.Vector3(0,0,0));
    this.scene.add(this.directionalLight);
    
    // Attaches to cursor
    const mesh = new THREE.Mesh(
      new THREE.SphereBufferGeometry(0.2, 16, 16),
      new THREE.MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        depthWrite: false,
        opacity: 0.001
      })
    );
    mesh.position.copy(new THREE.Vector3(0, 0, 0));
    this.cursor = mesh;
    this.scene.add(mesh);

    this.start();
  }
  
  _getDimensions() {
    if (!this.isSSR) {
      return {
        ...this.props,
        stageWidth: document.documentElement.clientWidth,
        stageHeight: document.documentElement.clientHeight,
      }
    }
    return this.props;
  }
  
  _resize() {
    let { stageWidth, stageHeight } = this._getDimensions();
    if (!stageWidth || !stageHeight) {
      return null;
    }
    
    this.newRatio = stageWidth / stageHeight;
    // console.log('_resize', stageWidth, stageHeight, this.newRatio);
    
    if (this.renderer && this.newRatio != this.lastRatio) {
      this.renderer.setSize(stageWidth, stageHeight);  
    }
    
    const { activeCamera } = this.state;
    if (this.cams[activeCamera] && this.newRatio != this.lastRatio) {
      this.cams[activeCamera].camera.aspect = this.newRatio;
      this.cams[activeCamera].camera.updateProjectionMatrix();
    }

    this.lastRatio = stageWidth / stageHeight;

    // To set the display settings of the Checklist
    if (this.prevScreenWidth !== stageWidth) {
      this.checkChecklistDisplay(0);
    }
    this.prevScreenWidth = stageWidth;
  }

  _setupEngine() {
    return new Promise((resolve, reject) => {
      const { stageWidth, stageHeight, stageRatioX, stageRatioY } = this._getDimensions();

      if (!stageWidth || !stageHeight) {
        return reject(new Error('Missing computed stage dimensions'));
      }

      // add renderer
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: false,
      });
      this.renderer.setSize(stageWidth, stageHeight);
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.gammaFactor = 2.2;
      
      this._resize();
      
      // console.log('renderer size', stageWidth, stageHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.gammaInput = true;
      this.renderer.gammaOutput = true;
      this.renderer.setClearColor("#000000");
      this.mount.appendChild(this.renderer.domElement);

      _.each([CAM_PLAYER_1], (camX) => {
        this.cams[camX] = {};
        this.cams[camX].camera = new THREE.PerspectiveCamera(CAM_FOV, stageRatioX/stageRatioY, CAM_MIN_CLIP, CAM_MAX_CLIP);
        this.cams[camX].camera.position.copy(CAM_PLAYER_1_DEFAULT_POSITION);
        this.cams[camX].camera.lookAt(POS_LOOK_AT[0]);
        this.cams[camX].camera.aspect = stageWidth / stageHeight;


        this.cams[camX].composer = new EffectComposer(this.renderer);      
        
        this.cams[camX].fxShockWave = new ShockWaveEffect(this.cams[camX].camera, new THREE.Vector3(0, 0, 0), {
          speed: 6,
          maxRadius: 0.01,
          waveSize: 0.03,
          amplitude: 0.01
        });

        // Glitch Effect
        this.cams[camX].fxGlitch = new GlitchEffect();
        this.cams[camX].fxGlitch.mode = GlitchMode.DISABLED; // GlitchMode.SPORADIC
        this.cams[camX].fxGlitch.delay = new THREE.Vector2(0.2, 0.5);
        this.cams[camX].fxGlitch.duration = new THREE.Vector2(1, 3);
        this.cams[camX].fxGlitch.chromaticAberrationOffset = new THREE.Vector2(10, 10);
        
        // Vignette Effect
        this.cams[camX].fxVignette = new VignetteEffect();
        this.cams[camX].fxVignette.eskil = false;    // Default: false
        this.cams[camX].fxVignette.uniforms.get("offset").value = 0;    // Default: 0.35
        this.cams[camX].fxVignette.uniforms.get("darkness").value = 0;  // Default: 0.75
        
        // BrightnessContrast Effect
        this.cams[camX].fxBrightnessContrast = new BrightnessContrastEffect({
          blendFunction: BlendFunction.OVERLAY
        });
        this.cams[camX].fxBrightnessContrast.uniforms.get("brightness").value = 0;
        this.cams[camX].fxBrightnessContrast.uniforms.get("contrast").value = 0;
        this.cams[camX].fxBrightnessContrast.blendMode.opacity.value = 1.0;
        
        this.cams[camX].fxBloom = new BloomEffect({
          blendFunction: BlendFunction.SCREEN,
          kernelSize: KernelSize.VERY_LARGE,
          luminanceThreshold: 0,
          luminanceSmoothing: 0.9,
          height: 720
        });
        
        this.cams[camX].depthPickingPass = new DepthPickingPass();
        
        this.cams[camX].fxCombinedPass = new EffectPass(this.cams[camX].camera,
                                                          this.cams[camX].fxGlitch,
                                                          this.cams[camX].fxBloom,
                                                          this.cams[camX].fxVignette,
                                                          this.cams[camX].fxBrightnessContrast,
                                                          this.cams[camX].fxShockWave,
                                                        );
        this.cams[camX].fxCombinedPass.renderToScreen = true;
        
        // Blur Pass - Effect
        this.cams[camX].fxBlurPass = new BlurPass();
        this.cams[camX].fxBlurPass.enabled = false;
        this.cams[camX].fxBlurPass.height = stageHeight;
        this.cams[camX].fxBlurPass.kernelSize = 5; // 5 == HUGE
        this.cams[camX].fxBlurPass.scale = 0; // 1.0 == max
        this.cams[camX].fxBlurPass.renderToScreen = false;

        this.cams[camX].renderPass = new RenderPass(this.scene, this.cams[camX].camera);
        
        this.cams[camX].composer.addPass(this.cams[camX].renderPass);
        this.cams[camX].composer.addPass(this.cams[camX].depthPickingPass);
        this.cams[camX].composer.addPass(this.cams[camX].fxCombinedPass);
        this.cams[camX].composer.addPass(this.cams[camX].fxBlurPass);
        
        this.renderer.domElement.addEventListener("mousemove", this, { passive: true });
        document.addEventListener("keyup", this);
      });
      return resolve();
    });
  }
  
  getActiveCamPosition() {
    const { activeCamera } = this.state;
    if (this.cams[activeCamera]) {
      return this.cams[activeCamera].camera.position;  
    }
    return {x:0, z:0};
  }
  
  startTweenBlur() {
    return new Promise(resolve => {
      const { activeCamera } = this.state;
      
      this.cams[activeCamera].fxCombinedPass.renderToScreen = false;
      
      // Blur Pass - Effect
      this.cams[activeCamera].fxBlurPass.enabled = true;
      this.cams[activeCamera].fxBlurPass.renderToScreen = true;
      
      let tween = new Tween({ s: 0 })
        .to({ s: 1 }, 1200)
        .easing(Easing.Cubic.Out)
        .on('update', ({ s }) => {
          this.cams[activeCamera].fxBlurPass.scale = s;
        })
        .on('complete', () => {
          return resolve();
        })
        .start();
    });
  }
  
  stopTweenBlur() {
    return new Promise(resolve => {
      const { activeCamera } = this.state;
            
      let tween = new Tween({ s: 1 })
        .to({ s: 0 }, 600)
        .easing(Easing.Cubic.Out)
        .on('update', ({ s }) => {
          this.cams[activeCamera].fxBlurPass.scale = s;
        })
        .on('complete', () => {
          this.cams[activeCamera].fxCombinedPass.renderToScreen = true;
          this.cams[activeCamera].fxBlurPass.enabled = false;
          this.cams[activeCamera].fxBlurPass.renderToScreen = false;
          return resolve();
        })
        .start();
    });
  }
  
  toggleVignette(v = null) {
    return new Promise(resolve => {
      const { activeCamera, hasVignette } = this.state;
      
      let vHasVignette = !hasVignette;
      if (v === true || v === false) {
        vHasVignette = v;
      }
      
      this.setState({
        hasVignette: vHasVignette
      }, () => {
        let vFrom = this.vignetteV;
        let vTo = 0.75;
        if (!this.state.hasVignette) {
          vFrom = this.vignetteV;
          vTo = 0;
        }
        if (this.tweenVignette) { this.tweenVignette.stop(); }
        this.tweenVignette = new Tween({ v: vFrom })
          .to({ v: vTo }, 3600)
          .easing(Easing.Cubic.Out)
          .on('update', ({ v }) => {
            this.vignetteV = v;
            this.cams[activeCamera].fxVignette.uniforms.get("offset").value = v;
            this.cams[activeCamera].fxVignette.uniforms.get("darkness").value = v;
          })
          .on('complete', () => {
            return resolve();
          })
          .start();
      });
    });
  }
  
  smoothTraverse(points) {
    const cbs = [];
    let i = 0;
    _.each(points, (p) => {
      cbs.push(
        (callback) => {
          let tween = new Tween({ x: this.camPosX, z: this.camPosZ, rY: this.camRotY })
            .to(p, 2000)
            .easing(Easing.Cubic.Out)
            .on('update', ({x, z, rY}) => {
              this.camRotY = rY;
              this.camPosX = x;
              this.camPosZ = z;
            })
            .on('complete', ({x, z, rY}) => {
              callback(null, ++i);    
            })
            .start();
        }
      )
    });
    
    series(cbs, (err, res) => {
      console.log("Traversal completed");
    });
  }

  /*
  plane-like rotation notes
  pitch = rotate on x / up+down
  roll = rotate on z / bank left+right
  yaw = rotate on y / left+right on pivot
  */

  _getClockMeta() {
    this.setState(game.getClockMeta());
    return game.getClockMeta();
  }
  
  showCallout() {
    let tween = new Tween({ top: this.calloutTop })
      .to({ top: 10 }, 1200)
      .easing(Easing.Back.Out)
      .on('update', ({ top }) => {
        this.calloutTop = top;
        this.mountCallout.style.top = top+'%';
      })
      .start();
  }
  
  hideCallout() {
    this.audioPlayer.stop();
    this.isAudioPlaying = false;
    this.stopTweenBlur();
    let tween = new Tween({ top: this.calloutTop })
      .to({ top: 150 }, 800)
      .easing(Easing.Cubic.Out)
      .on('update', ({ top }) => {
        this.calloutTop = top;
        this.mountCallout.style.top = top+'%';
      })
      .start();  
  }

  checkChecklistDisplay(delay = 3000) {
    // Hide the Checklist after few seconds on Mobile and display the Checklist Mini
    const { isShowBranding } = this.state;

    if (isShowBranding) return;

    _.delay(() => {
      const { stageWidth } = this._getDimensions();

      if (stageWidth < 767) {
        this.mountChecklist.style.left = CLOSE_X+'px';
        this.mountChecklistMini.style.left = OPEN_X_EDGE+'px';
        this.isChecklistOpen = false;
      } else {
        this.mountChecklist.style.left = OPEN_X+'px';
        this.mountChecklistMini.style.left = CLOSE_X+'px';
        this.isChecklistOpen = true;
      }
    }, delay);
  }
  
  _startSim() {
    // TODO: Start sequence!
    if (!this.isSSR) {
      this.ambiancePlayer.loop = true;
      this.ambiancePlayer.fadeIn = 3;
      this.ambiancePlayer.fadeOut = 2;
      this.ambiancePlayer.volume.value = -7; // -3
      this.ambiancePlayer.start();
    }
    new Tween({ v: 0.0, a: 1.0 })
      .to({ v: 0.35, a: 0.0 }, 5400)
      .easing(Easing.Cubic.Out)
      .on('update', ({ v, a }) => {
        this.coreAmbientLightValue = v;
        this.ambientLight.intensity = v;
        this.mountBranding.style.opacity = a;
      })
      .on('complete', () => {
        let tween2 = new Tween({ camRotX: this.camRotX })
          .to({ camRotX: 0 }, 3200)
          .easing(Easing.Cubic.Out)
          .on('update', ({ camRotX }) => {
            this.camRotX = camRotX;
          })
          .on('complete', () => {
            this.mountChecklist.style.left = OPEN_X+'px';
            this.mountControls.style.left = OPEN_X+'px';
            this.mountExit.style.right = 0+'px';
            this.toggleVignette();
            this.setState({
              isShowBranding: false
            });

            _.delay(() => {
              this.setState({
                isShowInstructions: true
              });
            }, 1000)

            this.checkChecklistDisplay()
          })
          .start();
      })
      .start(); 
  }
  
  _validateTasks() {
    const { tasks } = this.state;
    
    // console.log('_validateTasks', tasks, TASKS_COUNT);
    
    let c = 0;
    for(var i = 0; i<tasks.length; i++) {
      if (tasks[i] === true) {
        c++;
      }
    }
    if (c === TASKS_COUNT) {
      _.delay(() => {
        this.setState({ isCompleted: true });
      }, 2000);
    }

    this.setState({ completedTaskCount: c });
  }
  
  _renderBranding() {
    const { isLoaded } = this.state;
    
    return (
      <div className="proto--brand"
        ref={(mount) => {
          this.mountBranding = mount;
        }}
      >
        <div className="proto--brand--content"
          ref={(mount) => {
            this.mountBrand = mount;
          }}
        >
          <img src="/headphone.png" alt="headphone" />
          <h1>Best experienced with headphones</h1>
          { this._renderLoader() }
          { isLoaded && (
            <button onClick={(e) => {
              this._startSim();
            }}>
              Ready
            </button>
          )}
        </div>
      </div>
    );
  }
  
  _renderCallout() {
    return (
      <div className="proto--callout"
        ref={(mount) => {
          this.mountCallout = mount;
        }}
      >
        <div className="proto--callout--content">
          <h1></h1>
          <p>
            <img src="/protovoice.jpg" />
          </p>
          <p>
            Calling a friend...
          </p>
          <div className="proto--callout--controls">
            <span onClick={() => {
              this.hideCallout();
            }}>CLOSE</span>
          </div>
        </div>
      </div>
    );
  }

  _renderFrame() {
    const {
      stageWidth, stageHeight,
    } = this._getDimensions();

    const {
      delta,
      elapsed,
      dilatedElapsed,
      dilatedDelta,
      dilationFactor,
    } = this._getClockMeta();

    const activeCamera = CAM_PLAYER_1;

    this.setState({
      activeCamera,
    });

    this.scene.updateMatrixWorld();
    this.scene.traverse((object) => {
      if (object instanceof THREE.LOD) {
        object.update(this.cams[activeCamera].camera);
      }
    });
    
    this.cams[activeCamera].fxVignette.mode = this.hasFxGlitch ? GlitchMode.CONSTANT_MILD : GlitchMode.DISABLED;
    this.cams[activeCamera].fxGlitch.mode = this.hasFxGlitch ? GlitchMode.CONSTANT_MILD : GlitchMode.DISABLED;
  
    let dir = new THREE.Vector3();
    this.cams[activeCamera].camera.rotation.set(this.camRotX, this.camRotY, this.camRotZ);
    this.cams[activeCamera].camera.getWorldDirection(dir);
    if (this.camSpeed != 0) {
      this.cams[activeCamera].camera.position.addScaledVector(dir, this.camSpeed);  
      this.camPosX = this.cams[activeCamera].camera.position.x;
      this.camPosY = this.cams[activeCamera].camera.position.y;
      this.camPosZ = this.cams[activeCamera].camera.position.z;
    } else {
      this.cams[activeCamera].camera.position.set(this.camPosX, this.camPosY, this.camPosZ);  
    }

    // this.cams[currentActiveCamera].camera.lookAt(this.ship.mesh.position);

    this.renderer.setSize(stageWidth, stageHeight);
    this.cams[activeCamera].composer.render(delta);
  }

  renderScene = () => {
    const { isSceneInitialized } = this.state;

    this.initializeScene();

    // Even if paused, can handle in-game options
    // Menu, options, other effectors can happen here.

    if (game.isPaused) {
      return;
    }

    if (!isSceneInitialized) {
      return;
    }

    this._renderFrame();
  };
  
  tweenCounter(i, total) {
    if (!this.mountCounterBar) {
      // Elements unavailable
      return;
    }
    if (i !== total) {
      // console.log('progress:', ((i+1) / total) * 100);
      this.mountCounterBar.style.width = ((i+1) / total) * 100 + '%';
    } else {
      this.mountCounterBar.style.width = 100 + '%';
      // console.log('all loaded');
      this.mountLoader.style.opacity = 0; 
      this.mountLoader.style.height = 0;
    }
  }
  
  tweenLoader(i, total) {
    if (!this.mountLoader || !this.mountLoaderBar) {
      // Elements unavailable
      return;
    }
    if (i !== total) {
      // console.log('progress:', ((i+1) / total) * 100);
      this.mountLoaderBar.style.width = ((i+1) / total) * 100 + '%';
    } else {
      this.mountLoaderBar.style.width = 100 + '%';
      // console.log('all loaded');
      // this.mountLoader.style.top = "-30px"; 
    }
  }
  
  _renderLoader() {
    const { percentLoaded, loadingFile } = this.state;
    return (
      <div className="proto--loader"
        ref={(mount) => {
          this.mountLoader = mount;
        }}
      >
        <div className="proto--loader-bg">
          <div className="proto--loader-fg" 
            ref={(mount) => {
              this.mountCounterBar = mount;
            }}
          />
        </div>
        <span>{`Loading ${loadingFile} (${percentLoaded}%)`}</span>
        {/* <div className="proto--loader-bg _counter">
          <div className="proto--loader-fg" 
            ref={(mount) => {
              this.mountLoaderBar = mount;
            }}
          />
        </div> */}
      </div>
    );
  }
  
  _makeTween(t) {
    if (!this.tweenMove) { return; }
    
    clearTimeout(this.timerIdle);
    this.timerIdle = setTimeout(() => {
      // console.log('timed out', IDLE_LIMIT);
      scenarios.goSleep()
        .then((newSceneConfig) => {
          this._makeTween(newSceneConfig);
        })
        .catch(console.log);
    }, IDLE_LIMIT);

    this.toggleVignette(t.vignette || false);
    
    this.tweenMove.stop();
    this.tweenMove = new Tween({ 
      x: this.camPosX, 
      y: this.camPosY,  
      z: this.camPosZ, 
      rX: this.camRotX, 
      rY: this.camRotY, 
      rZ: this.camRotZ,
    })
      .to({ 
        x: t.x,
        y: t.y,
        z: t.z,
        rX: t.rX,
        rY: t.rY,
        rZ: t.rZ,
      }, t.duration)
        .easing(Easing.Cubic.Out)
        .on('update', ({x, y, z, rX, rY, rZ}) => {
          this.camRotX = rX;
          this.camRotY = rY;
          this.camRotZ = rZ;
          this.camPosX = x;
          this.camPosY = y;
          this.camPosZ = z;
        })
        .start();
  }

  _renderControls() {
    const forward = () => {
      this._fulfillTask(0);
      scenarios.goY(1)
        .then((newSceneConfig) => {
          this._makeTween(newSceneConfig);
        })
        .catch(console.log);
    }

    const right = () => {
      this._fulfillTask(0);
      scenarios.goX(1)
        .then((newSceneConfig) => {
          this._makeTween(newSceneConfig);
        })
        .catch(console.log);
    }

    const left = () => {
      this._fulfillTask(0);
      scenarios.goX(-1)
        .then((newSceneConfig) => {
          this._makeTween(newSceneConfig);
        })
        .catch(console.log);
    }

    const back = () => {
      this._fulfillTask(0);
      scenarios.goY(-1)
        .then((newSceneConfig) => {
          this._makeTween(newSceneConfig);
        })
        .catch(console.log);
    }

    const center = () => {
      scenarios.goCenter()
        .then((newSceneConfig) => {
          this._makeTween(newSceneConfig);
        })
        .catch(console.log);
    }

    return (
      <div className="proto--controls"
        ref={(mount) => {
          this.mountControls = mount;
        }}
      >
        <div className={"_f"} onClick={ forward }></div>
        <div className={"_l"} onClick={ left }></div>
        <div className={"_r"} onClick={ right }></div>
        <div className={"_b"} onClick={ back }></div>
        <div className={"_center"} onClick={ center }></div>
        <ul>
          <li className={"_f"}></li>
          <li className={"_l"}></li>
          <li className={"_r"}></li>
          <li className={"_b"}></li>
          <li className={"_center"}></li>
        </ul>
        <img className="main" src="/controls/main-container.png" alt="arrow controls" />
      </div>
    );
  }

  _renderExit() {
    return (
      <div className="proto--exit"
        ref={(mount) => {
          this.mountExit = mount;
        }}
        onClick={()=>{ this.setState({isShowExit: true}, () => { this.ambiancePlayer.stop(); this.audioPlayer.stop(); }); }}
      >
        Exit <span className="text">Experience</span>
      </div>
    );
  }

  _renderBoxExit() {
    return (
      <div className="proto--box _exit appear"
        ref={(mount) => {
          this.mountExit = mount;
        }}
      >
        <button className="close-btn" onClick={()=>{ this.setState({isShowExit: false}, () => { this.ambiancePlayer.start() }); }}></button>
        <div className="proto--box--content _help">
          <div className="wrapper">
            <div className="left">
              <div className="header">
                <div>
                  <h1>If you are experiencing symptoms of depression or are looking to support a loved one with depression, below is a list of resources that may help.</h1>
                </div>
              </div>
              <div className="support">
                <div className="support--item _dbsa">
                  <span><img src="/dbsa-wellness.png" /></span>
                  <div>Depression and Bipolar Support Alliance <br />(DBSA) Wellness Tracker
                    <a className="button" href="https://genesight.com/knowmentalhealth/resources/" target="_blank"><span>Learn More</span><i className="chevron"></i></a>
                  </div>
                </div>

                <div className="support--item _genetic">
                  <span><img src="/genetic-testing.png" /></span>
                  <div><span>GeneSight<sup>&#174;</sup> Psychotropic test</span>
                    <a className="button" href="https://genesight.com/" target="_blank"><span>Learn More</span><i className="chevron"></i></a>
                  </div>
                </div>

                <div className="support--item _online">
                  <span><img src="/online-support.png" /></span>
                  <div>Find an Online Support Group
                    <a className="button" href="https://www.dbsalliance.org/support/chapters-and-support-groups/online-support-groups/" target="_blank"><span>Learn More</span><i className="chevron"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <img className="logo" src="/genesight-logo-v2.png" alt="genesight-logo" />
              <p className="remember">Please remember, this kit is meant to bring to life just a few of the possible physical and emotional experiences associated with depression. It is meant to demonstrate how hard it is for people experiencing depression to seek and follow through with treatment. We invite you to visit our <a href="https://genesight.com/KnowMentalHealth/resources" target="_blank">resources page</a> to learn more about how you can support loved ones who may be living with depression.</p>
              <div className="thank--you">
                <h2>Thank you for taking the time to experience how depression can feel.</h2>
                <span className="button" onClick={()=>{ this.setState({isShowExit: false}, () => { this.ambiancePlayer.start() }); }}>Return to Experience</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  restart() {
    location.reload();
  }

  _renderRestart() {
    return (
      <div className="proto--restart"
        ref={(mount) => {
          this.mountRestart = mount;
        }}
        onClick={()=>{
          this.setState({ tasks: [] }, ()=>{
            this.restart();
          });
        }}
      >
        <i className="restart--icon"></i> Restart
      </div>
    );
  }

  _renderCompleted() {
    return (
      <div className="proto--completed"
        ref={(mount) => {
          this.mountCompleted = mount;
        }}
        onClick={()=>{ this._showCompletedPopup(); }}
      >
        <i className="check--icon"></i> Completed
      </div>
    );
  }

  _renderBoxCompleted() {
    return (
      <div className="proto--box _completed appear"
      >
        <button className="close-btn" onClick={()=>{ this.setState({isShowCompleted: false}); }}></button>
        <div className="proto--box--content _exit">
          <div className="wrapper">
            <div className="left">
              <div className="header">
                <div>
                  <h1>You’ve just experienced what it’s like to live with depression.</h1>
                  <h3>This is also how people with depression can feel when an antidepressant isn’t working for them.</h3>
                </div>
                <img className="logo" src="/genesight-logo-v2.png" alt="genesight-logo" />
              </div>
              <div className="stats">
                <h4>Take a deep breath.</h4>
                <div className="stats--item">
                  <span>
                    4 in 10
                    <img src="/four-people-in-line.png" alt="people in line" />
                  </span>
                  <p>of those diagnosed with depression say they aren’t confident that their medications will work for them.<sup>1</sup></p>
                </div>
                <div className="stats--item">
                  <span>
                    7 in 10
                    <img src="/seven-people-in-line.png" alt="people in line" />
                  </span>
                  <p>would feel optimistic if their doctor recommended a genetic test as part of <br />their treatment plan.<sup>1</sup></p>
                </div>
              </div>
              <div className="citation">
                1 GeneSight Mental Health Monitor
              </div>
              <div className="call--to--action">
                <h2>Fortunately, there's good reason to hope.</h2>
                <p className="light">With just a simple cheek swab, the GeneSight Psychotropic test provides clinicians with information about which medications may require dose adjustments, be less likely to work, or have an increased risk of side effects based on a patient’s genetic makeup. It’s one of many tools in a clinician's toolbox that may help get patients on the road to feeling more like themselves again.</p>
                {/* <p><b>If you or a loved one is ready to take the next step, please <br />click the link below to receive additional information.</b> </p>
                <Link to={`/`} className="button">
                  <span>Take the Next Step</span>
                </Link> */}
              </div>
            </div>
            <div className="right">
              <img className="logo" src="/genesight-logo-v2.png" alt="genesight-logo" />

              <div className="call--to--action">
                <p><b>If you or a loved one is ready to take the next step, please click the link below to receive additional information.</b> </p>
                <Link to={`https://genesight.com/take-the-next-step`} className="button">
                  <span>Take the Next Step</span>
                </Link>
                <Link to={`https://genesight.com/KnowMentalHealth/resources`} target="_blank" className="button">
                  <span>Learn More</span>
                </Link>
              </div>
              <div className="disclaimer">
                The GeneSight test must be ordered by and used only in consultation with a healthcare provider who can prescribe medications. The GeneSight test is intended to supplement a healthcare provider’s comprehensive medical assessment. As with all genetic tests, the GeneSight test results have limitations and do not constitute medical advice. Not all patients who receive the Genesight test will have improved outcomes. Do not make any changes to your current medications or dosing without consulting your healthcare provider.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  showInfoBox(index) {
    const delay = 13000;
    let { lastFinishedTask, tasks } = this.state;
    lastFinishedTask = index;

    this.setState({ lastFinishedTask });
    this.keepInfoBoxOpen = true;

    _.delay(() => {
      this.keepInfoBoxOpen = false;
    }, 100);
  }

  hideInfoBox() {
    this.setState({ lastFinishedTask: null });
  }

  handleClickEvent(event) {
    //  Hide Info box when clicking outside of it
    if (this.mountInfoBox && event.target !== this.mountInfoBox && event.target.parentElement !== this.mountInfoBox && !this.keepInfoBoxOpen) {
      this.hideInfoBox();
    }
  }

  _renderIntructionsBox() {
    return (
      <div className={`proto--box _instructions appear`}
        ref={(mount) => {
          this.mountInstructionsBox = mount;
        }}
      >
        <h2>Instructions</h2>
        <span>You’ll need to complete the tasks on the ‘to do’ list on the left. Use the arrow buttons to navigate and find the items to cross off your list. But first, you need to get out of bed.</span>
        <button className="button" onClick={(e) => {
          this.setState({isShowInstructions: false});
        }}>
          Begin
        </button>
      </div>
    );
  }

  _renderInfoBox(text) {
    const { lastFinishedTask } = this.state;

    return (
      <div className={`proto--info--box ${!this.isChecklistOpen ? '_centered' : ''}`}
        ref={(mount) => {
          this.mountInfoBox = mount;
        }}
      >
        <img src="/info-icon.png" alt="info icon" />
        <span>{ text }</span>
      </div>
    );
  }

  _renderCheckListMini() {
    const  { completedTaskCount } = this.state;

    return (
      <div className={`proto--checklist--mini ${completedTaskCount ? '_with-completed-task' : ''}`}
        ref={(mount) => {
          this.mountChecklistMini = mount;
        }}
        onClick={() => {
          this.mountChecklist.style.left = OPEN_X+'px';
          this.mountChecklistMini.style.left = CLOSE_X+'px';
          this.isChecklistOpen = !this.isChecklistOpen;
        }}
      >
        <img src="/notebook.png" alt="notebook" /> To do {completedTaskCount > 0 ? '(' + completedTaskCount + ' of ' + TASKS_COUNT + ')' : ''}
      </div>
    );
  }

  _renderChecklist() {
    const { tasks, lastFinishedTask } = this.state;
    
    return (
      <div className="proto--checklist"
        ref={(mount) => {
          this.mountChecklist = mount;
        }}
      >
        <button className="close-btn" onClick={() => {
          this.mountChecklist.style.left = CLOSE_X+'px';
          this.mountChecklistMini.style.left = OPEN_X_EDGE+'px';
          this.isChecklistOpen = !this.isChecklistOpen;
        }}></button>
        <h1>to do</h1>
        <ul>
          <li className={tasks[0] === true ? "_checked" : ""}>
            <span onClick={ () => { tasks[0] === true && this.showInfoBox(0) }}>
              Get out of bed<hr />
              { lastFinishedTask === 0 && this._renderInfoBox('Depression is sometimes associated with a change in energy, which may lead to feeling lethargic or sleepy much of the time If you’re also feeling weighed down-- literally or figuratively- it can be really hard to get out of bed.  It’s not laziness at all.') }
            </span>
          </li>
          <li className={tasks[1] === true ? "_checked" : ""}>
            <span onClick={ () => { tasks[1] === true && this.showInfoBox(1) }}>
              Eat something<hr />
              { lastFinishedTask === 1 && this._renderInfoBox('There is a strong connection between mood and food. Sometimes with depression, you’ll have no appetite at all, or food may lack flavor. Other times, you can’t stop yourself from eating. Both may lead to moodiness and trigger other depressive symptoms.') }
            </span>
          </li>
          <li className={tasks[2] === true ? "_checked" : ""}>
            <span onClick={ () => { tasks[2] === true && this.showInfoBox(2) }}>
              Call a friend (make plans)<hr />
              { lastFinishedTask === 2 && this._renderInfoBox('When you’re depressed, it can be a challenge to care about the things that normally interest you, like spending time with friends or family. Yet skipping these activities often leads to feeling isolated or lonely. This can be a vicious cycle.') }
            </span>
          </li>
          <li className={tasks[3] === true ? "_checked" : ""}>
            <span onClick={ () => { tasks[3] === true && this.showInfoBox(3) }}>
              Take medication<hr />
              { lastFinishedTask === 3 && this._renderInfoBox('Finding an antidepressant that works for you shouldn’t be a challenge; however, about half of people with clinical depression fail to respond to the first medication they are prescribed. That means that the medication didn’t alleviate your depression symptoms, and/or you’re also dealing with debilitating side effects.') }
            </span>
          </li>
          <li className={tasks[4] === true ? "_checked" : ""}>
            <span onClick={ () => { tasks[4] === true && this.showInfoBox(4) }}>
              Pick up clothes off the floor<hr />
              { lastFinishedTask === 4 && this._renderInfoBox('Frustration with yourself, others, and even circumstances are often associated with depression. This frustration can lead to a cycle of negative thoughts. One of these symptoms alone may not be cause for immediate concern, but when many are present at once, it could be time to see a doctor.') }
            </span>
          </li>
        </ul>
      </div>
    );
  }
  
  _renderDebugMini() {
    return (
      <div className="proto--debug"
        ref={(mount) => {
          this.mountDebugMini = mount;
        }}
      >
        <button
          type="button"
          className="proto--debug--toggle"
          onClick={() => {
            if (!this.isDebugOpen) {
              this.mountDebug.style.right = DEBUG_CLOSE_X+'px';
              this.mountDebugMini.style.right = DEBUG_OPEN_X+'px';
            } else {
              this.mountDebug.style.right = DEBUG_OPEN_X+'px';
              this.mountDebugMini.style.right = DEBUG_CLOSE_X+'px';
            }
            this.isDebugOpen = !this.isDebugOpen;
          }}
        >
         { !this.isDebugOpen ? "Close" : "Open" }
        </button>
        <div>{ VERSION }</div>
      </div>
    );
  }

  _renderDebug() {
    const {
      elapsed, dilatedElapsed, gameState,
    } = this.state;

// <br />
// { `Dilated: ${_.round(dilatedElapsed * 1000)}ms` }

    const { x, z } = this.getActiveCamPosition();
    const rY = Math.round( ( this.camRotY + Number.EPSILON ) * 100 ) / 100;

    return (
      <div className="proto--debug"
        ref={(mount) => {
          this.mountDebug = mount;
        }}
      >
        <h1>Proto Dev</h1>
        <div>{ `${_.round(elapsed * 1000)}ms` }</div>
        <div>{ `x:${Math.round(x)} z:${Math.round(z)}` }</div>
        <div>{ `rY:${rY}` }</div>
        <br />
        <div>Control</div>
        <button
          type="button"
          onClick={() => {
            let tween = new Tween({ speed: this.camSpeed + CAM_SPEED_F })
              .to({ speed: 0 }, 1200)
              .easing(Easing.Cubic.Out)
              .on('update', ({ speed }) => {
                this.camSpeed = speed;
              })
              .start();  
          }}
        >
          Forward
        </button>
        <br />
        <button
          type="button"
          onClick={() => {
            let tween = new Tween({ camRotY: this.camRotY })
              .to({ camRotY: this.camRotY + (Math.PI / 8) }, 1000)
              .easing(Easing.Cubic.Out)
              .on('update', ({ camRotY }) => {
                this.camRotY = camRotY;
              })
              .start();  
          }}
        >
          Left
        </button>
        <br />
        <button
          type="button"
          onClick={() => {
            let tween = new Tween({ camRotY: this.camRotY })
              .to({ camRotY: this.camRotY - (Math.PI / 8) }, 1000)
              .easing(Easing.Cubic.Out)
              .on('update', ({ camRotY }) => {
                this.camRotY = camRotY;
              })
              .start();
          }}
        >
          Right
        </button>
        <br />
        <button
          type="button"
          onClick={() => {
            let tween = new Tween({ speed: this.camSpeed - CAM_SPEED_B })
              .to({ speed: 0 }, 1200)
              .easing(Easing.Cubic.Out)
              .on('update', ({ speed }) => {
                this.camSpeed = speed;
              })
              .start();  
          }}
        >
          Back
        </button>
        <div>POI</div>
        <button
          type="button"
          onClick={() => {
            let { tasks } = this.state;
            tasks[0] = true;
            this.setState({ tasks }, this._validateTasks.bind(this));
            this.smoothTraverse(POI.A);
          }}
        >
          A
        </button>
        <br />
        <button
          type="button"
          onClick={() => {
            let { tasks } = this.state;
            tasks[1] = true;
            this.setState({ tasks }, this._validateTasks.bind(this));
            this.smoothTraverse(POI.B);
          }}
        >
          B
        </button>
        <button
        type="button"
        onClick={() => {
          let { tasks } = this.state;
          tasks[2] = true;
          this.setState({ tasks }, this._validateTasks.bind(this));
          this.smoothTraverse(POI.C);
        }}
        >
          C
        </button>
        <button
        type="button"
        onClick={() => {
          let { tasks } = this.state;
          tasks[3] = true;
          this.setState({ tasks }, this._validateTasks.bind(this));
          this.smoothTraverse(POI.C);
        }}
        >
          D
        </button>
        <button
        type="button"
        onClick={() => {
          let { tasks } = this.state;
          tasks[4] = true;
          this.setState({ tasks }, this._validateTasks.bind(this));
          this.smoothTraverse(POI.C);
        }}
        >
          E
        </button>
        <div>&nbsp;</div>
        <button
          type="button"
          className="proto--debug--toggle"
          onClick={() => {
            if (!this.isDebugOpen) {
              this.mountDebug.style.right = DEBUG_CLOSE_X+'px';
              this.mountDebugMini.style.right = DEBUG_OPEN_X+'px';
            } else {
              this.mountDebug.style.right = DEBUG_OPEN_X+'px';
              this.mountDebugMini.style.right = DEBUG_CLOSE_X+'px';
            }
            this.isDebugOpen = !this.isDebugOpen;
          }}
        >
         { !this.isDebugOpen ? "Close" : "Open" }
        </button>
        <div>{ VERSION }</div>
      </div>
    );
  }

  render() {
    const { stageWidth, stageHeight } = this._getDimensions();
    const {
      errorSystem, errorRender, isShowExit, isShowCompleted, isShowBranding, isShowDebug, isShowInstructions
    } = this.state;
    this._resize();
    return (
      <div
        ref={(mount) => {
          this.mount = mount;
        }}
        style={{
          width: `${stageWidth}px`,
          height: `${stageHeight}px`,
        }}
        onClick={ (event) => this.handleClickEvent(event) }
      >
        {
          (isShowBranding === true) && (
            this._renderBranding()
          )
        }
        { (isShowInstructions === true) && (
            this._renderIntructionsBox()
          )
        }
        { this._renderCallout() }
        {
          (isShowDebug === true) && (
            this._renderDebugMini()
          )
        }
        {
          (isShowDebug === true) && (
            this._renderDebug()
          )
        }
        { this._renderChecklist() }
        { this._renderCheckListMini() }
        { this._renderControls() }
        { this._renderExit() }
        { this._renderCompleted() }
        { this._renderRestart() }

        {
          (isShowExit === true) && (
            this._renderBoxExit()
          )
        }
        {
          (isShowCompleted === true) && (
            this._renderBoxCompleted()
          )
        }
        {
          errorSystem
            && errorRender.join('<br />')
        }
      </div>
    );
  }
}

const mapSizesToProps = ({ width }, { height }) => ({
  windowWidth: width,
  windowHeight: height,
});

export default withSizes(mapSizesToProps)(Dt);
