import React, { Component } from 'react';
import withSizes from 'react-sizes';
import { withI18n } from '@lingui/react';

import { TemplateWrapperContext } from '../components/TemplateWrapper';
import Dt from '../components/Dt';

const clsDecorator = 'decorator--proto';

class PageDt extends Component {
  static contextType = TemplateWrapperContext;
  
  constructor(props) {
    super(props);
    this._mount = null;
    this.state = {
      ratioX: 16,
      ratioY: 9,
      seed: 0,
    };
    this.isSSR = (typeof window === "undefined");
  }

  componentDidMount() {
    const { funcDecorator } = this.context;
    funcDecorator(clsDecorator);

    if (this._mount && !this.isSSR) {
      window.addEventListener("orientationchange", (event) => {        
        this.setState({
          ratioX: document.documentElement.clientWidth,
          ratioY: document.documentElement.clientHeight,
        }, () => {
          // console.log("orientation change", event.target.screen.orientation.angle, this.state);
          this._computeDimensions();  
        });
      });
    }
  }

  _computeDimensions = () => {
    let w = 1;
    let h = 1;
    let {
      ratioX,
      ratioY,
    } = this.state;
    if (this._mount && !this.isSSR) {
      ratioX = document.documentElement.clientWidth;
      ratioY = document.documentElement.clientHeight;

      w = ratioX;
      h = ratioY;
    }
    
    // console.log('computeDimensions', {
    //   w, h, ratioX, ratioY
    // });
    
    return {
      w, h, ratioX, ratioY
    };
  }

  _renderStage() {
    const {
      w,
      h,
      ratioX,
      ratioY,
    } = this._computeDimensions();

    return (
      <div
        ref={(_mount) => { this._mount = _mount; }}
        className="proto margin--bottom--sm"
      >
        <div
          className="proto--stage"
          style={{
            width: `${w}px`,
            height: `${h}px`,
          }}
        >
          <Dt
            stageWidth={w}
            stageHeight={h}
            stageRatioX={ratioX}
            stageRatioY={ratioY}
          />
        </div>
      </div>
    );
  }

  render() {
    let { seed } = this.state;
    return (
      this._renderStage()
    );
  }
}

const mapSizesToProps = ({ width }, { height }) => ({
  windowWidth: width,
  windowHeight: height,
});

export default withI18n()(withSizes(mapSizesToProps)(PageDt));
