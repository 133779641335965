const objs = [
	{
		obj: 'OneLargeModel_everything2',
		interactive: false,
		pos: [0, 0, 0],
		name: 'Room',
	},
	{
		obj: 'Bed',
		interactive: false,
		pos: [0, 0, 0],
		name: 'Set',
	},
	{
		obj: 'Laundry',
		interactive: true,
		pos: [0, 0, 0],
		vo: 'pick-up-clothes',
		task: 4,
		name: 'Task Object 0',
	},
	{
		obj: 'Phone',
		interactive: true,
		pos: [0, 0, 0],
		vo: 'call-a-friend',
		task: 2,
		name: 'Task Object 1',
	},
	{
		obj: 'Sandwich',
		interactive: true,
		pos: [0, 0, 0],
		vo: 'eat-something',
		task: 1,
		name: 'Task Object 2',
	},
	{
		obj: 'MedicineBottles',
		interactive: true,
		pos: [0, 0, 0],
		vo: 'take-meds-1',
		task: 3,
		name: 'Task Object 3',
	},
];

export default objs;