// Scenarios singleton

export const INTRO_DEFAULTS = {
	x:10.5,
	y:4,
	z:-15.5,
	rX:-0.8,
	rY:3.10,
	rZ:0,
};

export const DEFAULTS = {
	x:10.5,
	y:4,
	z:-15.5,
	rX:0,
	rY:3.10,
	rZ:0,
};

const slidesX = [
	{
		i: -3,
		name: "Left most lean",
		...DEFAULTS,
		x:12.0,
		z:-13.0,
		rX:-0.45,
		rY:4.50,
		vignette:true,
	},
	{
		i: -2,
		name: "Left more lean",
		...DEFAULTS,
		rX:-0.25,
		rY:4.00,
		x:11.5,
	},
	{
		i: -1,
		name: "Left lean",
		...DEFAULTS,
		rY:3.50,
		x:11.0,
	},
	{
		i: 0,
		name: "Default: Wake state",
		...DEFAULTS,
	},	
	{
		i: 1,
		name: "Right one",
		...DEFAULTS,
		x:10,
		rY:2.60,
	},
	{
		i: 2,
		name: "Right two",
		...DEFAULTS,
		x:9.5,
		y:4.15,
		z:-14.0,
		rY:1.90,
	},
	{
		i: 3,
		name: "Right three (counter)",
		...DEFAULTS,
		x:9.0,
		y:4.3,
		z:-13.0,
		rX:-0.65,
		rY:0.80,
		rZ:0.30,
	},
];

const slidesY = [
	{
		i: -2,
		name: "Sleep state",
		...DEFAULTS,
		rX:-0.9,
		y:3.7,
		vignette:true,
	},
	{
		i: -1,
		name: "Restless state",
		...DEFAULTS,
		rX:-0.45,
		y:3.9,
	},
	{
		i: 0,
		name: "Default: Wake state",
		...DEFAULTS,
	},	
	{
		i: 1,
		name: "Lean one",
		...DEFAULTS,
		rX:0.15,
		z:-12.5,
		y:4.1,
	},
	{
		i: 2,
		name: "Lean two",
		...DEFAULTS,
		rX:0.3,
		z:-10.5,
		y:5.2,
	},	
];


let instance = null;
class Scenarios {
	constructor() {
		if (!instance) { instance = this; }
		this.reset();

		return instance;
	}
	
	reset() {
		this.currentX = Math.floor((slidesX.length - 1) / 2);
		this.currentY = Math.floor((slidesY.length - 1) / 2);
		
		this.directionX = 1;
		this.directionY = 1;
		
		this.activeAxis = 'X';
		this.activeDirection = 1;
		
		// console.log('Scenario reset', {
		// 	currentX: this.currentX,
		// 	currentY: this.currentY,
		// });
	}
	
	goCenter() {
		return this.goX(0);
	}
	
	goSleep() {
		return this.goY(0);
	}
	
	goX(direction) {
		return new Promise((resolve, reject) => {
			let i = this.currentX;
			if (direction === 0) {
				i = Math.floor((slidesX.length - 1) / 2);
			} else if (direction > 0 && i+1 < slidesX.length) {
				i++;
			} else if (direction < 0 && i-1 >= 0) {
				i--;
			} else {
				return reject('goX error: x reached edge');	
			}
			this.currentX = i;
			this.currentY = Math.floor((slidesY.length - 1) / 2);
			// console.log('goX', {
			// 	direction,
			// 	currentX: this.currentX,
			// 	currentY: this.currentY
			// });
			return resolve(slidesX[i]);
		});
	}
	
	goY(direction) {
		return new Promise((resolve, reject) => {
			let i = this.currentY;
			if (direction === 0) {
				i = 0;
			} else if (direction > 0 && i+1 < slidesY.length) {
				i++;
			} else if (direction < 0 && i-1 >= 0) {
				i--;
			} else {
				return reject('goY error: y reached edge');	
			}
			this.currentX = Math.floor((slidesX.length - 1) / 2);
			this.currentY = i;
			// console.log('goY', {
			// 	direction,
			// 	currentX: this.currentX,
			// 	currentY: this.currentY
			// });
			return resolve(slidesY[i]);
		});
	}
}

export default new Scenarios();
