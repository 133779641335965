import * as THREE from 'three';

export const DefaultTimeDilationFactor = 1.0;
export const DefaultGamePausedState = false;

// Game configuration singleton
let instance = null;
class GameConfiguration {
  constructor() {
    if (!instance) { instance = this; }
    this.reset();
    return instance;
  }

  getClockMeta() {
    const delta = this.clock ? this.clock.getDelta() : 0;
    const elapsed = this.clock ? this.clock.getElapsedTime() - this.lastPaused : 0;
    const dilatedDelta = this.clock ? delta * DefaultTimeDilationFactor : 0;
    const dilatedElapsed = this.clock ? elapsed * DefaultTimeDilationFactor : 0;
    const dilationFactor = this.dilationFactor || DefaultTimeDilationFactor;

    return {
      delta, elapsed, dilatedDelta, dilatedElapsed, dilationFactor,
    };
  }

  getGameState() {
    return {
      isPaused: this.isPaused || DefaultGamePausedState,
    };
  }

  seed() {
    return this.value;
  }

  reset() {
    this.clock = new THREE.Clock();
    this.isPaused = DefaultGamePausedState;
    this.value = Math.random();
    this.pauseSnapshot = 0;
    this.lastPaused = 0;
    this.followAnimationDone = false;
  }

  setTime(seconds = 0) {
    this.lastPaused = this.clock.getElapsedTime() - seconds;
  }

  setTimeAtFollowAnimation() {
    this.followAnimationDone = false;
    this.setTime(9);
  }

  setTimeAndPause(seconds = 0) {
    this.setTime(seconds);
    this.pause();
    this.pauseSnapshot = this.clock.getElapsedTime();
  }

  start() {
    return new Promise((resolve) => {
      this.reset();
      this.clock.start();
      return resolve(this.getGameState());
    });
  }

  pause() {
    return new Promise((resolve, reject) => {
      // Should only be able to pause only if it is not paused
      if (!this.isPaused) {
        this.pauseSnapshot = this.clock.getElapsedTime();
        console.log('pause', this.pauseSnapshot);
        this.isPaused = true;
        return resolve(this.getGameState());
      }
      return reject(new Error('Game is already paused'));
    });
  }

  resume() {
    return new Promise((resolve, reject) => {
    // Should only be able to resume only if it is paused
      if (this.isPaused) {
        this.lastPaused += (this.clock.getElapsedTime() - this.pauseSnapshot);
        console.log('resume', this.clock.getElapsedTime(), this.pauseSnapshot);
        this.pauseSnapshot = 0;
        this.isPaused = false;
        return resolve(this.getGameState());
      }
      return reject(new Error('Game is already resumed'));
    });
  }

  // Alias to stop
  quit() {
    this.clock.stop();
  }
}

if (typeof window !== 'undefined') {
  window.Game = new GameConfiguration();
}
export default new GameConfiguration();
